import * as _esReflectConstruct2 from "core-js/modules/es.reflect.construct.js";

var _esReflectConstruct = "default" in _esReflectConstruct2 ? _esReflectConstruct2.default : _esReflectConstruct2;

import * as _esSymbol2 from "core-js/modules/es.symbol.js";

var _esSymbol = "default" in _esSymbol2 ? _esSymbol2.default : _esSymbol2;

import * as _esObjectGetOwnPropertyDescriptor2 from "core-js/modules/es.object.get-own-property-descriptor.js";

var _esObjectGetOwnPropertyDescriptor = "default" in _esObjectGetOwnPropertyDescriptor2 ? _esObjectGetOwnPropertyDescriptor2.default : _esObjectGetOwnPropertyDescriptor2;

import * as _esObjectGetOwnPropertyDescriptors2 from "core-js/modules/es.object.get-own-property-descriptors.js";

var _esObjectGetOwnPropertyDescriptors = "default" in _esObjectGetOwnPropertyDescriptors2 ? _esObjectGetOwnPropertyDescriptors2.default : _esObjectGetOwnPropertyDescriptors2;

import * as _esSymbolDescription2 from "core-js/modules/es.symbol.description.js";

var _esSymbolDescription = "default" in _esSymbolDescription2 ? _esSymbolDescription2.default : _esSymbolDescription2;

import * as _esSymbolIterator2 from "core-js/modules/es.symbol.iterator.js";

var _esSymbolIterator = "default" in _esSymbolIterator2 ? _esSymbolIterator2.default : _esSymbolIterator2;

import * as _esArrayIterator2 from "core-js/modules/es.array.iterator.js";

var _esArrayIterator = "default" in _esArrayIterator2 ? _esArrayIterator2.default : _esArrayIterator2;

import * as _esStringIterator2 from "core-js/modules/es.string.iterator.js";

var _esStringIterator = "default" in _esStringIterator2 ? _esStringIterator2.default : _esStringIterator2;

import * as _webDomCollectionsIterator2 from "core-js/modules/web.dom-collections.iterator.js";

var _webDomCollectionsIterator = "default" in _webDomCollectionsIterator2 ? _webDomCollectionsIterator2.default : _webDomCollectionsIterator2;

import * as _esRegexpConstructor2 from "core-js/modules/es.regexp.constructor.js";

var _esRegexpConstructor = "default" in _esRegexpConstructor2 ? _esRegexpConstructor2.default : _esRegexpConstructor2;

import * as _esRegexpExec2 from "core-js/modules/es.regexp.exec.js";

var _esRegexpExec = "default" in _esRegexpExec2 ? _esRegexpExec2.default : _esRegexpExec2;

import * as _esRegexpToString2 from "core-js/modules/es.regexp.to-string.js";

var _esRegexpToString = "default" in _esRegexpToString2 ? _esRegexpToString2.default : _esRegexpToString2;

import * as _esArraySort2 from "core-js/modules/es.array.sort.js";

var _esArraySort = "default" in _esArraySort2 ? _esArraySort2.default : _esArraySort2;

import * as _esObjectToString2 from "core-js/modules/es.object.to-string.js";

var _esObjectToString = "default" in _esObjectToString2 ? _esObjectToString2.default : _esObjectToString2;

import * as _webDomCollectionsForEach2 from "core-js/modules/web.dom-collections.for-each.js";

var _webDomCollectionsForEach = "default" in _webDomCollectionsForEach2 ? _webDomCollectionsForEach2.default : _webDomCollectionsForEach2;

import * as _esStringMatch2 from "core-js/modules/es.string.match.js";

var _esStringMatch = "default" in _esStringMatch2 ? _esStringMatch2.default : _esStringMatch2;

import * as _esArrayFilter2 from "core-js/modules/es.array.filter.js";

var _esArrayFilter = "default" in _esArrayFilter2 ? _esArrayFilter2.default : _esArrayFilter2;

import * as _esArrayIndexOf2 from "core-js/modules/es.array.index-of.js";

var _esArrayIndexOf = "default" in _esArrayIndexOf2 ? _esArrayIndexOf2.default : _esArrayIndexOf2;

import * as _esArrayMap2 from "core-js/modules/es.array.map.js";

var _esArrayMap = "default" in _esArrayMap2 ? _esArrayMap2.default : _esArrayMap2;

import * as _esArrayConcat2 from "core-js/modules/es.array.concat.js";

var _esArrayConcat = "default" in _esArrayConcat2 ? _esArrayConcat2.default : _esArrayConcat2;

import * as _esArraySlice2 from "core-js/modules/es.array.slice.js";

var _esArraySlice = "default" in _esArraySlice2 ? _esArraySlice2.default : _esArraySlice2;

import * as _esObjectAssign2 from "core-js/modules/es.object.assign.js";

var _esObjectAssign = "default" in _esObjectAssign2 ? _esObjectAssign2.default : _esObjectAssign2;

import * as _esObjectKeys2 from "core-js/modules/es.object.keys.js";

var _esObjectKeys = "default" in _esObjectKeys2 ? _esObjectKeys2.default : _esObjectKeys2;

import * as _esObjectGetPrototypeOf2 from "core-js/modules/es.object.get-prototype-of.js";

var _esObjectGetPrototypeOf = "default" in _esObjectGetPrototypeOf2 ? _esObjectGetPrototypeOf2.default : _esObjectGetPrototypeOf2;

import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

import * as _propTypes2 from "prop-types";

var _propTypes = "default" in _propTypes2 ? _propTypes2.default : _propTypes2;

import * as _textareaCaret2 from "textarea-caret";

var _textareaCaret = "default" in _textareaCaret2 ? _textareaCaret2.default : _textareaCaret2;

import * as _getInputSelection2 from "get-input-selection";

var _getInputSelection = "default" in _getInputSelection2 ? _getInputSelection2.default : _getInputSelection2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function () {
  "use strict";

  var e = {
    n: function (t) {
      var r = t && t.__esModule ? function () {
        return t.default;
      } : function () {
        return t;
      };
      return e.d(r, {
        a: r
      }), r;
    },
    d: function (t, r) {
      for (var n in r) e.o(r, n) && !e.o(t, n) && Object.defineProperty(t, n, {
        enumerable: !0,
        get: r[n]
      });
    },
    o: function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    },
    r: function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }
  },
      t = {};
  e.r(t), e.d(t, {
    default: function () {
      return w;
    }
  }), _esReflectConstruct, _esSymbol, _esObjectGetOwnPropertyDescriptor, _esObjectGetOwnPropertyDescriptors, _esSymbolDescription, _esSymbolIterator, _esArrayIterator, _esStringIterator, _webDomCollectionsIterator, _esRegexpConstructor, _esRegexpExec, _esRegexpToString, _esArraySort, _esObjectToString, _webDomCollectionsForEach, _esStringMatch, _esArrayFilter, _esArrayIndexOf, _esArrayMap, _esArrayConcat, _esArraySlice, _esObjectAssign, _esObjectKeys, _esObjectGetPrototypeOf;
  var r = _react,
      n = e.n(r),
      o = _propTypes,
      s = e.n(o),
      i = _textareaCaret,
      a = e.n(i),
      u = _getInputSelection,
      l = e.n(u);

  function c(e) {
    return c = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, c(e);
  }

  var f = ["Component", "defaultValue", "disabled", "onBlur", "value"];

  function p() {
    return p = Object.assign ? Object.assign.bind() : function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var r = arguments[t];

        for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
      }

      return e;
    }, p.apply(this || _global, arguments);
  }

  function h(e, t) {
    var r = Object.keys(e);

    if (Object.getOwnPropertySymbols) {
      var n = Object.getOwnPropertySymbols(e);
      t && (n = n.filter(function (t) {
        return Object.getOwnPropertyDescriptor(e, t).enumerable;
      })), r.push.apply(r, n);
    }

    return r;
  }

  function g(e) {
    for (var t = 1; t < arguments.length; t++) {
      var r = null != arguments[t] ? arguments[t] : {};
      t % 2 ? h(Object(r), !0).forEach(function (t) {
        d(e, t, r[t]);
      }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(r)) : h(Object(r)).forEach(function (t) {
        Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(r, t));
      });
    }

    return e;
  }

  function d(e, t, r) {
    return t in e ? Object.defineProperty(e, t, {
      value: r,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = r, e;
  }

  function y(e, t) {
    for (var r = 0; r < t.length; r++) {
      var n = t[r];
      n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
    }
  }

  function b(e, t) {
    return b = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (e, t) {
      return e.__proto__ = t, e;
    }, b(e, t);
  }

  function m(e, t) {
    if (t && ("object" === c(t) || "function" == typeof t)) return t;
    if (void 0 !== t) throw new TypeError("Derived constructors may only return object or undefined");
    return v(e);
  }

  function v(e) {
    if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return e;
  }

  function j(e) {
    return j = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    }, j(e);
  }

  var O = {
    Component: s().oneOfType([s().string, s().elementType]),
    defaultValue: s().string,
    disabled: s().bool,
    maxOptions: s().number,
    onBlur: s().func,
    onChange: s().func,
    onKeyDown: s().func,
    onRequestOptions: s().func,
    onSelect: s().func,
    changeOnSelect: s().func,
    options: s().oneOfType([s().object, s().arrayOf(s().string)]),
    regex: s().string,
    matchAny: s().bool,
    minChars: s().number,
    requestOnlyIfNoOptions: s().bool,
    spaceRemovers: s().arrayOf(s().string),
    spacer: s().string,
    trigger: s().oneOfType([s().string, s().arrayOf(s().string)]),
    value: s().string,
    offsetX: s().number,
    offsetY: s().number,
    passThroughEnter: s().bool
  },
      S = function (e) {
    !function (e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          writable: !0,
          configurable: !0
        }
      }), Object.defineProperty(e, "prototype", {
        writable: !1
      }), t && b(e, t);
    }(h, e);
    var t,
        o,
        s,
        i,
        c = (s = h, i = function () {
      if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
      if (Reflect.construct.sham) return !1;
      if ("function" == typeof Proxy) return !0;

      try {
        return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})), !0;
      } catch (e) {
        return !1;
      }
    }(), function () {
      var e,
          t = j(s);

      if (i) {
        var r = j(this || _global).constructor;
        e = Reflect.construct(t, arguments, r);
      } else e = t.apply(this || _global, arguments);

      return m(this || _global, e);
    });

    function h(e) {
      var t;
      return function (e, t) {
        if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
      }(this || _global, h), (t = c.call(this || _global, e)).isTrigger = t.isTrigger.bind(v(t)), t.arrayTriggerMatch = t.arrayTriggerMatch.bind(v(t)), t.getMatch = t.getMatch.bind(v(t)), t.handleChange = t.handleChange.bind(v(t)), t.handleKeyDown = t.handleKeyDown.bind(v(t)), t.handleResize = t.handleResize.bind(v(t)), t.handleSelection = t.handleSelection.bind(v(t)), t.updateCaretPosition = t.updateCaretPosition.bind(v(t)), t.updateHelper = t.updateHelper.bind(v(t)), t.resetHelper = t.resetHelper.bind(v(t)), t.renderAutocompleteList = t.renderAutocompleteList.bind(v(t)), t.state = {
        helperVisible: !1,
        left: 0,
        trigger: null,
        matchLength: 0,
        matchStart: 0,
        options: [],
        selection: 0,
        top: 0,
        value: null
      }, t.recentValue = e.defaultValue, t.enableSpaceRemovers = !1, t.refInput = (0, r.createRef)(), t;
    }

    return t = h, (o = [{
      key: "componentDidMount",
      value: function () {
        window.addEventListener("resize", (this || _global).handleResize);
      }
    }, {
      key: "componentDidUpdate",
      value: function (e) {
        var t = (this || _global).props.options,
            r = (this || _global).state.caret;
        t.length !== e.options.length && this.updateHelper((this || _global).recentValue, r, t);
      }
    }, {
      key: "componentWillUnmount",
      value: function () {
        window.removeEventListener("resize", (this || _global).handleResize);
      }
    }, {
      key: "getMatch",
      value: function (e, t, r) {
        var n = (this || _global).props,
            o = n.trigger,
            s = n.matchAny,
            i = n.regex,
            a = new RegExp(i),
            u = o;
        Array.isArray(u) || (u = new Array(o)), u.sort();
        var l = r;
        Array.isArray(r) && u.forEach(function (e) {
          l[e] = r;
        });

        for (var c = this.arrayTriggerMatch(u, a), f = null, p = 0; p < c.length; p++) for (var h = c[p], d = h.triggerStr, y = h.triggerMatch, b = h.triggerLength, m = t - 1; m >= 0; --m) {
          var v = e.substring(m, t).match(a),
              j = -1;

          if (b > 0) {
            var O = y ? m : m - b + 1;
            if (O < 0) break;
            if (this.isTrigger(d, e, O) && (j = O + b), !v && j < 0) break;
          } else {
            if (v && m > 0) continue;
            if (t - (j = 0 === m && v ? 0 : m + 1) == 0) break;
          }

          j >= 0 && function () {
            var r = l[d];
            if (null == r) return "continue";
            var n = e.substring(j, t),
                o = r.filter(function (e) {
              var t = e.toLowerCase().indexOf(n.toLowerCase());
              return -1 !== t && (s || 0 === t);
            }),
                i = d,
                a = n.length;
            f = null === f ? {
              trigger: i,
              matchStart: j,
              matchLength: a,
              options: o
            } : g(g({}, f), {}, {
              trigger: i,
              matchStart: j,
              matchLength: a,
              options: o
            });
          }();
        }

        return f;
      }
    }, {
      key: "arrayTriggerMatch",
      value: function (e, t) {
        return e.map(function (e) {
          return {
            triggerStr: e,
            triggerMatch: e.match(t),
            triggerLength: e.length
          };
        });
      }
    }, {
      key: "isTrigger",
      value: function (e, t, r) {
        return !e || !e.length || t.substr(r, e.length) === e;
      }
    }, {
      key: "handleChange",
      value: function (e) {
        var t = (this || _global).props,
            r = t.onChange,
            n = t.options,
            o = t.spaceRemovers,
            s = t.spacer,
            i = t.value,
            a = (this || _global).recentValue,
            u = e.target.value,
            c = l()(e.target).end;
        if (u.length || this.setState({
          helperVisible: !1
        }), (this || _global).recentValue = u, this.setState({
          caret: c,
          value: e.target.value
        }), !u.length || !c) return r(e.target.value);

        if ((this || _global).enableSpaceRemovers && o.length && u.length > 2 && s.length) {
          for (var f = 0; f < Math.max(a.length, u.length); ++f) if (a[f] !== u[f]) {
            if (f >= 2 && u[f - 1] === s && -1 === o.indexOf(u[f - 2]) && -1 !== o.indexOf(u[f]) && this.getMatch(u.substring(0, f - 2), c - 3, n)) {
              var p = "".concat(u.slice(0, f - 1)).concat(u.slice(f, f + 1)).concat(u.slice(f - 1, f)).concat(u.slice(f + 1));
              return this.updateCaretPosition(f + 1), (this || _global).refInput.current.value = p, i || this.setState({
                value: p
              }), r(p);
            }

            break;
          }

          (this || _global).enableSpaceRemovers = !1;
        }

        return this.updateHelper(u, c, n), i || this.setState({
          value: e.target.value
        }), r(e.target.value);
      }
    }, {
      key: "handleKeyDown",
      value: function (e) {
        var t = (this || _global).state,
            r = t.helperVisible,
            n = t.options,
            o = t.selection,
            s = (this || _global).props,
            i = s.onKeyDown,
            a = s.passThroughEnter;
        if (r) switch (e.keyCode) {
          case 27:
            e.preventDefault(), this.resetHelper();
            break;

          case 38:
            e.preventDefault(), this.setState({
              selection: (n.length + o - 1) % n.length
            });
            break;

          case 40:
            e.preventDefault(), this.setState({
              selection: (o + 1) % n.length
            });
            break;

          case 14:
          case 13:
            a || e.preventDefault(), this.handleSelection(o);
            break;

          case 9:
            this.handleSelection(o);
            break;

          default:
            i(e);
        } else i(e);
      }
    }, {
      key: "handleResize",
      value: function () {
        this.setState({
          helperVisible: !1
        });
      }
    }, {
      key: "handleSelection",
      value: function (e) {
        var t = (this || _global).props,
            r = t.spacer,
            n = t.onSelect,
            o = t.changeOnSelect,
            s = (this || _global).state,
            i = s.matchStart,
            a = s.matchLength,
            u = s.options,
            l = s.trigger,
            c = u[e],
            f = (this || _global).recentValue,
            p = 0 === l.length ? "" : f.substring(0, i - l.length),
            h = f.substring(i + a),
            g = {
          target: (this || _global).refInput.current
        },
            d = o(l, c);
        g.target.value = "".concat(p).concat(d).concat(r).concat(h), this.handleChange(g), n(g.target.value), this.resetHelper(), this.updateCaretPosition(p.length + d.length + 1), (this || _global).enableSpaceRemovers = !0;
      }
    }, {
      key: "updateCaretPosition",
      value: function (e) {
        var t = this || _global;
        this.setState({
          caret: e
        }, function () {
          return (0, u.setCaretPosition)(t.refInput.current, e);
        });
      }
    }, {
      key: "updateHelper",
      value: function (e, t, r) {
        var n = (this || _global).refInput.current,
            o = this.getMatch(e, t, r);

        if (o) {
          var s = a()(n, t),
              i = n.getBoundingClientRect(),
              u = s.top + n.offsetTop,
              l = Math.min(s.left + n.offsetLeft - 10, n.offsetLeft + i.width - 100),
              c = (this || _global).props,
              f = c.minChars,
              p = c.onRequestOptions,
              h = c.requestOnlyIfNoOptions;
          o.matchLength >= f && (o.options.length > 1 || 1 === o.options.length && (o.options[0].length !== o.matchLength || 1 === o.options[0].length)) ? this.setState(g({
            helperVisible: !0,
            top: u,
            left: l
          }, o)) : (h && o.options.length || p(e.substr(o.matchStart, o.matchLength)), this.resetHelper());
        } else this.resetHelper();
      }
    }, {
      key: "resetHelper",
      value: function () {
        this.setState({
          helperVisible: !1,
          selection: 0
        });
      }
    }, {
      key: "renderAutocompleteList",
      value: function () {
        var e = this || _global,
            t = (this || _global).state,
            r = t.helperVisible,
            o = t.left,
            s = t.matchStart,
            i = t.matchLength,
            a = t.options,
            u = t.selection,
            l = t.top,
            c = t.value;
        if (!r) return null;
        var f = (this || _global).props,
            p = f.maxOptions,
            h = f.offsetX,
            g = f.offsetY;
        if (0 === a.length) return null;
        if (u >= a.length) return this.setState({
          selection: 0
        }), null;
        var d = 0 === p ? a.length : p,
            y = a.slice(0, d).map(function (t, r) {
          var o = t.toLowerCase().indexOf(c.substr(s, i).toLowerCase());
          return n().createElement("li", {
            className: r === u ? "active" : null,
            key: t,
            onClick: function () {
              e.handleSelection(r);
            },
            onMouseEnter: function () {
              e.setState({
                selection: r
              });
            }
          }, t.slice(0, o), n().createElement("strong", null, t.substr(o, i)), t.slice(o + i));
        });
        return n().createElement("ul", {
          className: "react-autocomplete-input",
          style: {
            left: o + h,
            top: l + g
          }
        }, y);
      }
    }, {
      key: "render",
      value: function () {
        var e = (this || _global).props,
            t = e.Component,
            r = e.defaultValue,
            o = e.disabled,
            s = e.onBlur,
            i = e.value,
            a = function (e, t) {
          if (null == e) return {};

          var r,
              n,
              o = function (e, t) {
            if (null == e) return {};
            var r,
                n,
                o = {},
                s = Object.keys(e);

            for (n = 0; n < s.length; n++) r = s[n], t.indexOf(r) >= 0 || (o[r] = e[r]);

            return o;
          }(e, t);

          if (Object.getOwnPropertySymbols) {
            var s = Object.getOwnPropertySymbols(e);

            for (n = 0; n < s.length; n++) r = s[n], t.indexOf(r) >= 0 || Object.prototype.propertyIsEnumerable.call(e, r) && (o[r] = e[r]);
          }

          return o;
        }(e, f),
            u = (this || _global).state.value,
            l = Object.assign({}, a);

        Object.keys(O).forEach(function (e) {
          delete l[e];
        });
        var c = "";
        return null != i ? c = i : u ? c = u : r && (c = r), n().createElement(n().Fragment, null, n().createElement(t, p({
          disabled: o,
          onBlur: s,
          onChange: (this || _global).handleChange,
          onKeyDown: (this || _global).handleKeyDown,
          ref: (this || _global).refInput,
          value: c
        }, l)), this.renderAutocompleteList());
      }
    }]) && y(t.prototype, o), Object.defineProperty(t, "prototype", {
      writable: !1
    }), h;
  }(n().Component);

  S.propTypes = O, S.defaultProps = {
    Component: "textarea",
    defaultValue: "",
    disabled: !1,
    maxOptions: 6,
    onBlur: function () {},
    onChange: function () {},
    onKeyDown: function () {},
    onRequestOptions: function () {},
    onSelect: function () {},
    changeOnSelect: function (e, t) {
      return e + t;
    },
    options: [],
    regex: "^[A-Za-z0-9\\-_]+$",
    matchAny: !1,
    minChars: 0,
    requestOnlyIfNoOptions: !0,
    spaceRemovers: [",", ".", "!", "?"],
    spacer: " ",
    trigger: "@",
    offsetX: 0,
    offsetY: 0,
    value: null,
    passThroughEnter: !1
  };
  var w = S;
  exports = t;
}();
export default exports;